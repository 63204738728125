import React from 'react';
import { useState, useEffect } from 'react';

function Header({ isDarkMode, onThemeToggle }) {
  return (
    <header
      style={{
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: isDarkMode ? '#121212' : '#f5f5f5',
        /* boxShadow: '0 2px 4px rgba(0,0,0,0.1)', */
        transition: 'background-color 0.3s ease',
      }}
    >
      <div className="logo" style={{ cursor: 'pointer', color: isDarkMode ? 'white' : '#333' }}>
        🔮
      </div>
      <button
        onClick={onThemeToggle}
        style={{
          padding: '8px 16px',
          borderRadius: '12px',
          border: 'none',
          backgroundColor: isDarkMode ? '#333' : '#f0f0f0',
          color: isDarkMode ? 'white' : '#333',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          fontSize: '16px',
          transition: 'all 0.3s ease',
        }}
      >
        {isDarkMode ? '☀️' : '🌙'}
      </button>
    </header>
  );
}

export default Header;
