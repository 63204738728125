import React from 'react';

function Footer({ isDarkMode }) {
  return (
    <footer
      style={{
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: isDarkMode ? '#121212' : '#f5f5f5',
        transition: 'background-color 0.3s ease',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <div style={{ fontSize: '9px', color: isDarkMode ? 'white' : '#333' }}>
        © 2024 Oraclescan. All rights reserved.
      </div>
      <div style={{ fontSize: '12px', display: 'flex', gap: '20px' }}>
        <a
          href="https://github.com/alikonuk1"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: isDarkMode ? 'white' : '#333',
            textDecoration: 'none',
          }}
        >
          GitHub
        </a>
        <a
          href="https://x.com/slot1024_eth"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: isDarkMode ? 'white' : '#333',
            textDecoration: 'none',
          }}
        >
          Twitter
        </a>
      </div>
    </footer>
  );
}

export default Footer;
