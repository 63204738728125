import { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import {
  CONTRACT_BTC_CHAINLINK_MAINNET,
  ABI_BTC_CHAINLINK_MAINNET,
  CONTRACT_API3SERVERV1_BASE,
  ABI_API3SERVERV1_BASE,
} from './data/abi.js';

function App() {
  const [chainlinkPrice, setChainlinkPrice] = useState(null);
  const [api3Price, setApi3Price] = useState(null);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Handle theme toggle
  const toggleTheme = useCallback(() => {
    setIsDarkMode((prev) => !prev);
  }, []);

  // Update theme attribute on body
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        // Connect to Ethereum mainnet
        const ethProvider = new ethers.providers.JsonRpcProvider('https://rpc.ankr.com/eth');
        // Connect to Base
        const baseProvider = new ethers.providers.JsonRpcProvider('https://rpc.ankr.com/base');

        // Create contract instances
        const chainlinkContract = new ethers.Contract(
          CONTRACT_BTC_CHAINLINK_MAINNET,
          ABI_BTC_CHAINLINK_MAINNET,
          ethProvider
        );
        const api3Contract = new ethers.Contract(CONTRACT_API3SERVERV1_BASE, ABI_API3SERVERV1_BASE, baseProvider);

        const btcDatafeedId = '0x14068a17632066e15265d11c7a71a3c9a53c278f2c917bd7597f3786d498892c';

        // Fetch latest prices
        const [chainlinkData, api3Data] = await Promise.all([
          chainlinkContract.latestRoundData(),
          api3Contract.readDataFeedWithId(btcDatafeedId),
        ]);

        // Convert prices from wei (8 decimals for both feeds)
        let chainlinkPriceUSD = null;
        let api3PriceUSD = null;

        if (chainlinkData && chainlinkData.answer) {
          chainlinkPriceUSD = parseFloat(ethers.utils.formatUnits(chainlinkData.answer, 8));
          console.log('chainlink: ', chainlinkPriceUSD);
        }

        if (api3Data && typeof api3Data.value !== 'undefined') {
          // API3 returns {value: int224, timestamp: uint32}
          const api3BigNumber = ethers.BigNumber.from(api3Data.value);
          api3PriceUSD = parseFloat(ethers.utils.formatUnits(api3BigNumber, 18));
          console.log('api3: ', api3PriceUSD);
        }

        setChainlinkPrice(chainlinkPriceUSD);
        setApi3Price(api3PriceUSD);
        setError(null);
      } catch (err) {
        console.error('Error fetching prices:', err);
        setError('Error fetching prices. Please try again later.');
      }
    };

    fetchPrices();
    // Set up interval to fetch prices every 30 seconds
    const interval = setInterval(fetchPrices, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <Header isDarkMode={isDarkMode} onThemeToggle={toggleTheme} />
      <div className="container" style={{ marginBottom: '80px' }}>
        <h1>BTC/USD Price</h1>

        {error ? (
          <div className="error">{error}</div>
        ) : (
          <div className="price-cards">
            <div className="price-card">
              <h2>API3 Price Feed</h2>
              <div className="price">
                {api3Price
                  ? `$${parseFloat(api3Price).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}`
                  : 'Loading...'}
              </div>
            </div>

            <div className="price-card">
              <h2>Chainlink Price Feed</h2>
              <div className="price">
                {chainlinkPrice
                  ? `$${chainlinkPrice.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}`
                  : 'Loading...'}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer isDarkMode={isDarkMode} />
    </div>
  );
}

export default App;
